import React from "react"
import { Layout } from "../components/layout"
import styles from "../styles/style"

const Suggestions = () => {
  return (
    <Layout>
      <div className={` ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <h1>Suggestions</h1>
        </div>
      </div>
    </Layout>
  )
}

export default Suggestions
